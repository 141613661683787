<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="search__container">
      <common-search :fields="fields" @search="onSearch"></common-search>
    </div>
    <div class="table__container">
      <a-table
        :columns="tblColumns"
        :loading="loading"
        :data-source="tblData"
        :pagination="pagination"
        rowKey="orderSn"
        @change="onPage"
      >
        <template slot="orderStatusValue" slot-scope="text">
          <span
            v-if="text === '未发货' || text === '部分发货'"
            class="status--orange"
          >
            {{ text }}
          </span>
          <span v-else>
            {{ text }}
          </span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a
              @click="onDetail(record.orderSn)"
              v-if="record.orderStatusValue === '完成发货'"
            >
              查看
            </a>
            <a v-else @click="onDetail(record.orderSn)">发货</a>
          </a-space>
        </template>
      </a-table>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { tsFormat } from "@/components/DateUtils";
import {
  fetchCorporationInfoByKeyword,
  toDeliverChangeOrder
} from "@/services/OrderManageService";
import { loadArea } from "@/services/DealerService";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    width: "15%"
  },
  {
    title: "换货机构",
    dataIndex: "purchaserCorporationName",
    width: "15%"
  },
  {
    title: "客户发货地区",
    dataIndex: "consigneeAreaName",
    width: "15%"
  },
  {
    title: "订单金额（元）",
    dataIndex: "totalFactoryPrice",
    width: "10%"
  },
  {
    title: "原订单号（采购）",
    dataIndex: "orderPurchaseSn",
    width: "15%"
  },
  {
    title: "发货状态",
    dataIndex: "orderStatusValue",
    scopedSlots: { customRender: "orderStatusValue" },
    width: "10%"
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "15%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "5%"
  }
];

export default {
  name: "ToDeliverChangeOrderList",
  components: {
    Breadcrumb
  },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "发货状态",
          key: "deliverStatus",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "未发货",
              value: "not_consignment"
            },
            {
              label: "部分发货",
              value: "portion_consignment"
            },
            {
              label: "完成发货",
              value: "finish_consignment"
            }
          ]
        },
        {
          label: "申请时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "原订单号",
          key: "purchaseOrderSn",
          component: "j-input"
        },
        {
          label: "换货机构",
          key: "purchaserDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "发货地址",
          key: "registeredProvinceCode,registeredCityCode,registeredCountyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        }
      ],
      form: {},
      areaData: [],
      tblColumns,
      tblData: [],
      loading: false,
      pagination: {
        showQuickJumper: true
      },
      tsFormat
    };
  },
  activated() {
    this.loadList();
  },
  methods: {
    // 搜索下单机构
    async onSearchCorporation(value) {
      let corporationList = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          corporationList.push({
            label: `${item.corporationName}(${item.distributorTypeValue})`,
            value: item.distributorId
          });
        });
      }
      return corporationList;
    },
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaData;
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        orderSn: this.form.orderSn || "",
        purchaseOrderSn: this.form.purchaseOrderSn || "",
        purchaserDistributorId: this.form.purchaserDistributorId || "",
        deliverStatus: this.form.deliverStatus || "",
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        registeredProvinceCode: this.form.registeredProvinceCode
          ? this.form.registeredProvinceCode
          : "",
        registeredCityCode: this.form.registeredCityCode
          ? this.form.registeredCityCode
          : "",
        registeredCountyCode: this.form.registeredCountyCode
          ? this.form.registeredCountyCode
          : "",
        factoryId: localStorage.getItem("distributorId"),
        orderCategory: "EXCHANGE",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      toDeliverChangeOrder(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 查看
    onDetail(orderSn) {
      this.$router.push({
        name: "ToReceiverOrderDetail",
        params: { orderSn: orderSn, type: "deliver" }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 72px !important;
  text-align: left !important;
}

/deep/ .ant-form-item {
  margin-right: 40px;
}

.status--orange {
  color: #ff8600;
}
</style>
